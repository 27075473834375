.btn-cf {
  border-width: 0; // Custom border width
}
.icon-button-legend {
  font-size: 2rem;
}
/* Apply viewport height at medium (md) breakpoint and up */
@media (min-width: 768px) {
  .vh-md-100 {
    height: 100vh;
  }
}
@media (max-width: 766px) {
  /* Vos styles spécifiquement pour extra small (xs) vont ici */
  .vh-sm-100 {
    height: calc(100vh - 7rem);
  }
}

.h5,
h5 {
  font-size: 1rem;
}

.wrapper {
  background: #13627f;
  padding: 0.8rem;
}

.toggle_radio {
  position: relative;
  background: rgba(255, 255, 255, 0.1);
  margin: 4px auto;
  overflow: hidden;
  padding: 0 !important;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  position: relative;
  height: 26px;
  width: 318px;
}
.toggle_radio > * {
  float: left;
}
.toggle_radio input[type='radio'] {
  display: none;
}
.toggle_radio label {
  font: 90%/1.618 'Source Sans Pro';
  color: rgba(255, 255, 255, 0.9);
  z-index: 0;
  display: block;
  width: 100px;
  height: 20px;
  margin: 3px 3px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1;
  text-align: center;
}
.toggle_option_slider {
  width: 100px;
  height: 20px;
  position: absolute;
  top: 3px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.sidebar {
  background: rgba(12, 57, 73, 1);
}
.sidebar-scroll {
  overflow-x: auto; /* Enables horizontal scrolling */
  white-space: nowrap; /* Prevents wrapping of content */
  min-width: 200px;
}

.form-select:disabled {
  background-color: transparent;
  border: 1px solid #515151;
}

.modal-backdrop {
  --bs-backdrop-bg: transparent;
}

.modal {
  --bs-modal-bg: #13627f;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  text-transform: capitalize;
}

@media (min-width: 576px) {
  .modal-sm {
    --bs-modal-width: 310px;
  }
}

@media (min-width: 768px) {
  .col-md-2 {
    flex: 0 0 auto;
    width: 20%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 60%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 80%;
  }
}

.btn-cf2,
.btn-cf2:hover,
.btn-cf2:active {
  background: #e9f7fc;
  color: #004d66;
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 600;
  max-width: 70%;
  border-color: #e9f7fc;
}

.text {
  font-size: 0.9rem;
}

/* hiding first and last lines in cartesian grid */
.recharts-wrapper .recharts-cartesian-grid-horizontal line:first-child,
.recharts-wrapper .recharts-cartesian-grid-horizontal line:last-child {
  stroke-opacity: 0;
}

.tooltip-zoom {
  color: #004d66 !important;
}

.arrow-down {
  margin: 0 auto;
  display: block;
}

.custom-tooltip > .tooltip-inner {
  background-color: #fff;
  color: #000;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 5px;
  font-weight: 500;
  display: block !important;
}

.custom-tooltip > .tooltip-arrow {
  display: none !important;
}

.btn-check:checked + .custom,
:not(.btn-check) + .custom:active,
.custom:first-child:active,
.custom.active,
.custom.show {
  color: rgba(0, 0, 0, 1);
  background-color: rgba(138, 202, 234, 1);
  border-color: var(--bs-btn-active-border-color);
}

.content {
  background: rgba(10, 90, 114, 1);
  border-radius: 8px;
  // max-width: 208px;
  overflow-x: auto;
}

.fs-strong {
  font-size: 1.15rem;
}

.fs-span {
  font-size: 0.8rem;
  float: right;
  position: relative;
  top: -3px;
}

@media (max-width: 1240px) {
  .title h5,
  .title .fs-strong,
  .title,
  .fs-6 {
    font-size: 0.9rem !important;
  }
  .sub-title {
    font-size: 0.65rem !important;
  }
  .bar-chart {
    width: 1.5em !important;
  }
  .bar-text .text {
    font-size: 0.72rem !important;
  }
  .bar-text .icon {
    height: 1rem !important;
    width: 1rem !important;
  }
  .bar-chart-row .bar {
    height: 6.4rem !important;
  }
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(19, 98, 127, 0.9);
}

::-webkit-scrollbar {
  width: 12px;
  background-color: rgba(19, 98, 127, 0.9);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(19, 98, 127, 0.9);
  border: 1px solid rgba(21, 37, 44, 1);
}

.subTitle {
  font-size: 0.75rem;
}

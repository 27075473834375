$background-color_1: rgba(0, 0, 0, 0.8);
$background-color_2: rgba(255, 255, 255, 0.1);

.point-overlay {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  //   background: radial-gradient(transparent 150px, rgba(0, 0, 0, 0.8) 150px);
  //   background: -moz-radial-gradient(transparent 150px, rgba(0, 0, 0, 0.8) 150px);
  //   background: -webkit-radial-gradient(
  //     transparent 150px,
  //     rgba(0, 0, 0, 0.8) 150px
  //   );
  //   background: -ms-radial-gradient(transparent 150px, rgba(0, 0, 0, 0.8) 150px);
  //   background: -o-radial-gradient(transparent 150px, rgba(0, 0, 0, 0.8) 150px);
  pointer-events: none; /* send mouse events beneath this layer */
}
.point-container {
  position: relative;
  border-radius: 50%;
  border: 2px dashed white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 5;
  background: rgba(0, 0, 0, 0.2);
}
.point-close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  background: none;
  border: none;
  cursor: pointer;
}
.point-content {
  padding: 10px;
  font-size: 16px;
}

.point-number {
  position: absolute;
  width: 49px;
  height: 43px;
  cursor: pointer;

  &.number-1 {
    top: 280px;
    left: 455px;
  }
  &.number-2 {
    top: 70px;
    left: 478px;
  }
  &.number-3 {
    top: 460px;
    left: 280px;
  }
  &.number-4 {
    top: 100px;
    left: 520px;
  }
}

.arrow-1 {
  position: absolute;
  width: 55px;
  height: 41px;
  cursor: pointer;
  top: 366px;
  left: 620px;
}

.arrow-2 {
  position: absolute;
  width: 66px;
  height: 33px;
  cursor: pointer;
  top: 238px;
  left: 600px;
}

.arrow-3 {
  position: absolute;
  width: 82px;
  height: 59px;
  cursor: pointer;
  top: 46px;
  left: 215px;
}

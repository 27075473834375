.custom-toggle {
  font-family: 'Nunito Sans', sans-serif;
  &.btn-group {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.btn-lg,
  &.btn-group-lg > .btn {
    --bs-btn-padding-y: 0.3rem;
    --bs-btn-border-radius: 4px;
    --bs-btn-font-size: 0.8rem;
    --bs-btn-font-weight: 700;
    --bs-btn-focus-box-shadow: none;
    font-family: 'Nunito Sans', sans-serif;
    font-weight: 700;
    font-size: 0.8rem;
  }

  .btn-secondary {
    --bs-btn-color: rgba(233, 247, 252, 1);
    --bs-btn-bg: transparent;
    --bs-btn-border-color: rgba(233, 247, 252, 1);
    --bs-btn-hover-color: rgba(0, 77, 102, 1);
    --bs-btn-hover-bg: rgba(233, 247, 252, 1);
    --bs-btn-hover-border-color: rgba(233, 247, 252, 1);
    --bs-btn-focus-shadow-rgb: 55, 70, 76;
    --bs-btn-active-color: rgba(0, 77, 102, 1);
    --bs-btn-active-bg: rgba(233, 247, 252, 1);
    --bs-btn-active-border-color: rgba(233, 247, 252, 1);
    --bs-btn-active-shadow: 0;
    --bs-btn-disabled-color: #515151;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #515151;
  }
}

$body-bg: #10181D;
$body-color: #FFFFFF;
// Override default breakpoint values
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px, // Change this as needed
  lg: 992px, // Change this as needed
  xl: 1200px, // Change this as needed
  xxl: 1400px // You can add more breakpoints if needed
);

// Optionally, adjust the container max-widths for each breakpoint
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
);
$grid-breakpoints: map-merge(
  $grid-breakpoints,
  (
    "xxxl": 2000px, // You can add more breakpoints if needed
  )
);

// Optionally, extend container max-widths for the new breakpoints
$container-max-widths: map-merge(
  $container-max-widths,
  (
    "xxxl": 1920px, // Matching container width for xxxl
  )
);

$grid-gutter-width: 0;

// 2. Override primary and secondary color variables
$primary: #0b3a49; // New primary color
$secondary: #14252C; // New secondary color

// 3. Optionally, adjust other variables based on the new colors
// $success: #28a745;
// $danger: #dc3545;
// $warning: #ffc107;
// $info: #17a2b8;

$btn-border-radius: .5rem;

$btn-padding-y: .5rem; // Custom vertical padding
$btn-padding-x: .5rem; // Custom horizontal padding


$box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
$btn-close-color: white;
$form-check-input-bg: grey;

$form-range-track-height: 1.5rem;
$form-range-track-bg: #13627f;
$form-range-bg: #FFFFFF;

$modal-backdrop-bg: black;
$modal-backdrop-opacity: 1;
@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?q2etnh');
  src:  url('../fonts/icomoon.eot?q2etnh#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?q2etnh') format('truetype'),
    url('../fonts/icomoon.woff?q2etnh') format('woff'),
    url('../fonts/icomoon.svg?q2etnh#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change ../fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-photovoltaic-park .path1:before {
  content: "\e900";
  color: rgb(218, 197, 5);
}
.icon-photovoltaic-park .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-photovoltaic-park .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-photovoltaic-park .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-photovoltaic-park .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-photovoltaic-park .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-marine-wind-farm .path1:before {
  content: "\e906";
  color: rgb(205, 153, 237);
}
.icon-marine-wind-farm .path2:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-marine-wind-farm .path3:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-marine-wind-farm .path4:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-marine-wind-farm .path5:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-marine-wind-farm .path6:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-marine-wind-farm .path7:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-marine-wind-farm .path8:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(17, 22, 26);
}
.icon-eye:before {
  content: "\e90e";
  color: #fff;
}
.icon-info:before {
  content: "\e90f";
  color: #fff;
}
.icon-button-legend .path1:before {
  content: "\e910";
  color: rgb(233, 247, 252);
}
.icon-button-legend .path2:before {
  content: "\e911";
  margin-left: -1.025390625em;
  color: rgb(0, 77, 102);
}
.icon-nuclear-plant .path1:before {
  content: "\e912";
  color: rgb(255, 152, 97);
}
.icon-nuclear-plant .path2:before {
  content: "\e913";
  margin-left: -1.03125em;
  color: rgb(17, 22, 26);
}
.icon-nuclear-plant .path3:before {
  content: "\e914";
  margin-left: -1.03125em;
  color: rgb(17, 22, 26);
}

.recharts-wrapper {
  font-family: 'Nunito Sans', sans-serif;

  .recharts-legend-item-text > span {
    color: $btn-close-color !important;
    margin-left: 0.3rem;
  }

  .label-top {
    transform: translateY(-5px);
    fill: $btn-close-color;
    font-size: 1.2rem;
  }
  .label {
    transform: translateY(4px);
    fill: $btn-close-color !important;
    font-size: 1rem;
  }
}
